import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import { useStyles } from '../styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrice } from 'core/contexts/price';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { useExperience } from 'core/contexts/experience';
import {
    Grid,
    Typography,
    useMediaQuery,
    Divider,
    Button,
    TextField,
    InputLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditIcon from 'assets/icons/edit-white.svg';
import { Field, Form } from 'react-final-form';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import { toast } from 'react-toastify';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

const getMaxValue = (expValues, maxCols, setMaxCols) => {
  const maxQuantity = expValues.reduce(
    (maxQuantity, exp) => Math.max(maxQuantity, ...exp.price.map(price => price.quantity)),
    0
  );

  return maxQuantity > maxCols ? (setMaxCols(maxQuantity), maxQuantity) : maxCols;
};
const getExpValues = (expPg, maxCols, setMaxCols) => {
    const allExps = expPg?.map((exp, idx) => {
        const { name, price } = exp;

        const experience = {
            id: idx,
            experience: name,
            currency: price[0]?.currency,
            isOpenCurrency: false,
            isOpenExp: false,
            pgId: price[0]?.priceGroupId,
        };
        let currentQuantity = 1;
        const maxValue = getMaxValue(expPg, maxCols, setMaxCols);
        for (let index = 0; index < maxValue; index++) {
            const currentPrice = price.find(
                (p) => p.quantity === currentQuantity
            );

            if (currentPrice) {
                experience[`price${index}`] = {
                    priceQuantity: currentPrice.price,
                    priceId: currentPrice.id,
                    priceGroupId: currentPrice.priceGroupId,
                };
            } else {
                experience[`price${index}`] = {
                    priceQuantity: 0,
                    priceId: 0,
                    priceGroupId: price[0]?.priceGroupId,
                };
            }
            currentQuantity++;
        }

        return experience;
    });
    return allExps;
};

const getColumns = (expValues) => {
    const columns = [
        { columnId: 'experience', width: 150 },
        { columnId: 'currency', width: 150 },
    ];
    const priceColumns = [];
    expValues.forEach((exp) => {
        Object.keys(exp).forEach((key) => {
            if (key.startsWith('price')) {
                if (!priceColumns.includes(key)) {
                    priceColumns.push(key);
                }
            }
        });
    });
    priceColumns.forEach((priceKey) => {
        columns.push({ columnId: priceKey, width: 100 });
    });

    return columns;
};

const getHeaderRow = (maxValue, translate) => {
    const headerRow = {
        rowId: 'header',
        cells: [
            { type: 'header', text: `${translate('price.header_exp')}` },
            { type: 'header', text: `${translate('price.header_currency')}` },
        ],
    };
    for (let index = 0; index < maxValue; index++) {
        const value = index + 1;
        headerRow.cells.push({
            type: 'header',
            text: value.toString(),
        });
    }

    return headerRow;
};

const getRows = (expValues, translate) => {
    return expValues.map((exp, idx) => {
        return {
            rowId: idx,
            cells: [
                expValues.length - 1 === idx
                    ? {
                          id: idx,
                          type: 'dropdown',
                          selectedValue: exp.experience,
                          isOpen: exp.isOpenExp,
                          values: exp?.valuesDropdown,
                      }
                    : {
                          id: idx,
                          type: 'text',
                          value: exp.experience,
                          text: exp.experience,
                      },
                {
                    id: idx,
                    type: 'dropdown',
                    selectedValue: exp.currency,
                    isOpen: exp.isOpenCurrency,
                    priceGroupId: exp.pgId,
                    values: [
                        {
                            label: `${translate('price.select_currency')}`,
                            value: `${translate('price.select_currency')}`,
                            isDisabled: true,
                        },
                        { label: 'COP', value: 'COP' },
                        { label: 'USD', value: 'USD' },
                    ],
                },
                ...Object.keys(exp).reduce((acc, key) => {
                    if (
                        key.includes('price') &&
                        exp[key] !== undefined &&
                        exp[key].priceQuantity !== undefined
                    ) {
                        acc.push({
                            id: idx,
                            type: 'number',
                            priceId: exp[key].priceId,
                            priceGroupId: exp[key].priceGroupId,
                            value: exp[key].priceQuantity,
                        });
                    }
                    return acc;
                }, []),
            ],
        };
    });
};

function createEmptyPrices(count = 10) {
    const emptyPrices = {};
    for (let i = 0; i < count; i++) {
        emptyPrices[`price${i}`] = {
            priceQuantity: 0,
            priceId: 0,
            priceGroupId: 0,
        };
    }
    return emptyPrices;
}

const newValue = (maxValue, expValues, valuesDropdown, translate) => {
    const emptyPrices = createEmptyPrices(maxValue);

    return {
        id: expValues.length,
        experience: `${translate('price.select_exp')}`,
        currency: `${translate('price.select_currency')}`,
        isOpenCurrency: false,
        isOpenExp: false,
        pgId: 0,
        valuesDropdown,
        ...emptyPrices,
    };
};

const applyChanges = (changes, prevDetails = [], newValue, setNewValue) => {
    changes.forEach((change) => {
        const dataRowId = change.rowId;
        const fieldName = change.columnId;
        let dataRow = prevDetails.find((d) => d.id === dataRowId);
        if (
            change.type === 'number' &&
            typeof dataRow[fieldName].priceQuantity === 'number'
        ) {
            if (prevDetails.length - 1 === change.newCell.id) {
                dataRow[fieldName].priceQuantity = change.newCell.value;
                const quantity = fieldName.replace(/\D/g, '');
                const resultQuantity = quantity ? parseInt(quantity, 10) : 0;
                setNewValue({
                    ...newValue,
                    newValue: true,
                    prices: {
                        price: change.newCell.value,
                        quantity: resultQuantity + 1,
                        type: 1,
                        currency:
                            newValue.currency || dataRow.currency || 'COP',
                    },
                });
            } else {
                if (dataRow[fieldName].priceId !== 0) {
                    dataRow[fieldName].priceQuantity = change.newCell.value;
                    setNewValue({
                        priceGroupId: change.newCell.priceGroupId,
                        price: change.newCell.value,
                        id: change.newCell.priceId,
                    });
                } else {
                    dataRow[fieldName].priceQuantity = change.newCell.value;
                    const quantity = fieldName.replace(/\D/g, '');
                    const resultQuantity = quantity
                        ? parseInt(quantity, 10)
                        : 0;
                    setNewValue({
                        priceGroupId: change.newCell.priceGroupId,
                        price: change.newCell.value,
                        quantity: resultQuantity + 1,
                        type: 1,
                        currency: dataRow.currency,
                    });
                }
            }
        } else if (change.type === 'dropdown') {
            const { selectedValue, priceGroupId } = change.newCell;
            if (prevDetails.length - 1 === change.newCell.id) {
                console.log('Estoy creando un new expPg');
                if (change.previousCell.selectedValue !== selectedValue) {
                    dataRow[fieldName] = selectedValue;
                    const experience = change.newCell.values.find(
                        (exp) => exp.label === selectedValue
                    );
                    if (fieldName === 'experience') {
                        setNewValue({
                            ...newValue,
                            newValue: true,
                            experienceId: experience.experienceId,
                        });
                    } else {
                        setNewValue({
                            ...newValue,
                            newValue: true,
                            currency: selectedValue,
                        });
                    }
                }
            } else {
                if (change.previousCell.selectedValue !== selectedValue) {
                    dataRow[fieldName] = selectedValue;
                    setNewValue({
                        currency: selectedValue,
                        priceGroupId,
                    });
                }
            }
            if (fieldName === 'experience') {
                dataRow.isOpenExp = change.newCell.isOpen;
            } else {
                dataRow.isOpenCurrency = change.newCell.isOpen;
            }
        } else {
            console.log('ERROR', change.type, dataRow[fieldName]);
        }
    });
    return [...prevDetails];
};

const PricePage = () => {
    const {
        getExpPg,
        addExpPg,
        expPg,
        expPgTab,
        experiencerId,
        addPrice,
        updatePrice,
        updatePriceCurrency,
        getPriceGroupTab,
        updatePriceGroupTab,
        addPriceGroupTab,
        loading,
        setExpPgTab
    } = usePrice();
    const { getExperiences, experiences } = useExperience();
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [canEdit, setCanEdit] = useState(false);
    const { t: translate } = useTranslation();
    const locationParam = document.location.href.split('/');
    const priceGroupTabId = locationParam[locationParam.length - 1]
        ? parseInt(locationParam[locationParam.length - 1])
        : false;

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [expData, setExpData] = useState([]);
    const [newData, setNewData] = useState({});
    const [expPgTabData, setExpPgTabData] = useState({});
    const [valuesExperiences, setValuesExperiences] = useState([]);
    const [maxCols, setMaxCols] = useState(10);

    useEffect(() => {
        if (priceGroupTabId !== 0) {
            getExpPg(priceGroupTabId);
            getPriceGroupTab(priceGroupTabId);
            setExpPgTabData(expPgTab);
        } else {
            setCanEdit(true);
            setExpPgTabData({});
            setExpPgTab({})
        }
    }, [priceGroupTabId]);

    useEffect(() => {
        if (experiencerId !== null) {
            getExperiences({
                nameSearch: '',
                experiencerIdSearch: experiencerId,
                pager: {
                    column: 'ci_id',
                    direction: 'desc',
                    page: 1,
                    pageSize: 50,
                },
            });
        }
        if (priceGroupTabId !== 0) {
            getPriceGroupTab(priceGroupTabId);
        }
    }, [experiencerId, priceGroupTabId]);

    useEffect(() => {
        if (expPg.length > 0 && valuesExperiences.length > 0) {
            const expValues = getExpValues(expPg, maxCols, setMaxCols);
            setExpData(expValues);
            const maxValue = getMaxValue(expPg, maxCols, setMaxCols);

            if (expValues.length > 0) {
                const newData = [
                    ...expValues,
                    newValue(maxValue, expValues, valuesExperiences, translate),
                ];
                setExpData(newData);
            }
        } else if (expPg.length === 0 && valuesExperiences.length > 0) {
            const newData = [newValue(10, [], valuesExperiences, translate)];
            if (newData.length > 0) {
                setExpData(newData);
            }
        }
    }, [expPg, valuesExperiences, maxCols]);

    useEffect(() => {
        if (experiences.length > 0) {
            const expsNames = expPg.map((item) => item.name);
            const auxExps = experiences
                .filter((item) => !expsNames.includes(item.name))
                .map((item) => ({
                    label: item.name,
                    value: item.name,
                    experienceId: item.id,
                }));
            setValuesExperiences([
                {
                    label: `${translate('price.select_exp')}`,
                    value: `${translate('price.select_exp')}`,
                },
                ...auxExps,
            ]);
        }
    }, [experiences, expPg]);

    useEffect(() => {
        if (expData?.length > 0) {
            let maxValue = getMaxValue(expPg, maxCols, setMaxCols);

            const newRows = [
                getHeaderRow(maxValue, translate),
                ...getRows(expData, translate),
            ];
            if (JSON.stringify(newRows) !== JSON.stringify(rows)) {
                setColumns(getColumns(expData));
                setRows(newRows);
            }
        }
    }, [expData, maxCols]);

    useEffect(() => {
        const handleNewData = async () => {
            if (
                newData.id &&
                newData.priceGroupId !== null &&
                !newData.newValue &&
                newData?.price > 0
            ) {
                updatePrice(newData.priceGroupId, newData.id, newData);
                setNewData({});
            } else if (
                !newData.id &&
                newData.quantity &&
                newData.priceGroupId !== null &&
                !newData.newValue &&
                newData?.price > 0
            ) {
                addPrice(newData.priceGroupId, newData);
                setNewData({});
            } else if (
                !newData.id &&
                !newData.quantity &&
                newData.currency &&
                newData.priceGroupId !== null &&
                !newData.newValue
            ) {
                updatePriceCurrency(newData.priceGroupId, {
                    currency: newData.currency,
                });
                setNewData({});
            } else if (newData.newValue) {
                if (
                    newData.currency &&
                    newData.prices?.price > 0 &&
                    newData.experienceId &&
                    priceGroupTabId !== 0
                ) {
                    try {
                        await addExpPg({
                            experienceId: newData?.experienceId,
                            priceGroupTabId,
                            prices: [newData?.prices],
                        });
                        getExpPg(priceGroupTabId);
                        setNewData({});
                    } catch (error) {
                        console.error(
                            'Error al agregar o obtener el experience price group',
                            error
                        );
                    }
                }
            }
        };
        if (newData) {
            handleNewData();
        }
    }, [newData]);

    const onSubmitCols = (value, form) => {
        const { colsValue } = value;
        if (colsValue >= 1 && colsValue <= 20) {
            setMaxCols(maxCols + parseInt(colsValue));
        }

        form.reset();
    };

    const handleChanges = (changes) => {
        setExpData((prevExp) => {
            const updatedExp = [...prevExp];
            applyChanges(changes, updatedExp, newData, setNewData);
            return updatedExp;
        });
    };

    const [expPgTabInfo, setExpPgTabInfo] = useState({});
    const [expPgTabChanged, setExpPgTabChanged] = useState(false);
    const history = useHistory();

    const onSubmit = async (values) => {
        try {
            const valuesClon = { ...values };

            if (priceGroupTabId == 0) {
                if (
                    !expPgTabChanged ||
                    !valuesClon.name ||
                    !valuesClon.experiencerId
                ) {
                    toast.error('Por favor llene todos los campos!', {
                        position: 'top-right',
                        theme: 'colored',
                    });
                    throw new Error('');
                } else {
                    const newPgt = await addPriceGroupTab(valuesClon);
                    getPriceGroupTab(newPgt.id);
                    history.push(`/price/${newPgt.id}`);
                    setCanEdit(false);
                }
            } else {
                if (
                    !expPgTabChanged ||
                    !valuesClon.name ||
                    !valuesClon.experiencerId
                ) {
                    toast.error('Por favor llene todos los campos!', {
                        position: 'top-right',
                        theme: 'colored',
                    });
                    throw new Error('');
                } else {
                    await updatePriceGroupTab(priceGroupTabId, valuesClon);
                    getPriceGroupTab(priceGroupTabId);
                    setCanEdit(false);
                    toast.success(
                        `${translate('price.price_data_succesfully_changed')}`,
                        {
                            position: 'top-right',
                            theme: 'colored',
                        }
                    );
                }
            }
        } catch (error) {
            toast.error(error, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Price'} />

            {loading ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Title value={expPgTab?.name || 'PriceGroup'} size={'l'} />

                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            ...expPgTab,
                        }}
                        render={({ handleSubmit, form }) => (
                            <form id="form" onSubmit={handleSubmit}>
                                <div>
                                    {priceGroupTabId !== 0 ? (
                                        <div
                                            className={classes.divEditButton}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                                variant="h5"
                                                className={classes.text}>
                                                {translate(
                                                    'price.edit_price_data'
                                                )}
                                            </Typography>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setCanEdit(!canEdit);
                                                }}
                                                style={{
                                                    height: 40,
                                                    margin: 'inherit',
                                                    backgroundColor: '#71ba6c',
                                                    color: 'white',
                                                    // alignItems: 'center'
                                                }}
                                                className={classes.editButton}>
                                                <img
                                                    src={EditIcon}
                                                    width={20}
                                                    alt="Edit"
                                                />
                                                {isSmall ? null : <>&nbsp;</>}
                                                {isSmall
                                                    ? null
                                                    : `${translate(
                                                          'price.edit'
                                                      )}`}
                                            </Button>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <Divider />

                                    <div style={{ marginTop: '20px' }}>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                            spacing={3}>
                                            <Grid item xs={4} sm={3}>
                                                <Field
                                                    name="name"
                                                    id="name"
                                                    component={TextInput}
                                                    hintText={translate(
                                                        'price.name'
                                                    )}
                                                    label={translate(
                                                        'price.name'
                                                    )}
                                                    onFieldChange={(value) => {
                                                        expPgTabInfo.name =
                                                            value;
                                                        setExpPgTabInfo(
                                                            expPgTabInfo
                                                        );

                                                        setExpPgTabChanged(
                                                            true
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>

                                            {priceGroupTabId === 0 && (
                                                <Grid item xs={4} sm={3}>
                                                    <Field
                                                        name="status"
                                                        id="status"
                                                        component={TextInput}
                                                        hintText={translate(
                                                            'Status'
                                                        )}
                                                        label={translate(
                                                            'Status'
                                                        )}
                                                        onFieldChange={(
                                                            value
                                                        ) => {
                                                            expPgTabInfo.status =
                                                                value;
                                                            setExpPgTabInfo(
                                                                expPgTabInfo
                                                            );
                                                            setExpPgTabChanged(
                                                                true
                                                            );
                                                        }}
                                                        disabled={!canEdit}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid item xs={4} sm={3}>
                                                <Field
                                                    id="experiencerId"
                                                    name="experiencerId"
                                                    component={TextInput}
                                                    hintText={translate(
                                                        'price.experiencerId'
                                                    )}
                                                    label={translate(
                                                        'price.experiencerId'
                                                    )}
                                                    onFieldChange={(value) => {
                                                        expPgTabInfo.experiencerId =
                                                            value;
                                                        setExpPgTabInfo(
                                                            expPgTabInfo
                                                        );

                                                        setExpPgTabChanged(
                                                            true
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                <Grid
                                    item
                                    container
                                    justify="flex-end"
                                    alignContent="flex-end">
                                    <Grid
                                        item
                                        container
                                        style={{
                                            width: 'auto',
                                            marginTop: '12px',
                                        }}>
                                        <Button
                                            className="rounded mr-3 height-40 w-100px"
                                            disabled={!canEdit}
                                            type="button"
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return form.reset(e);
                                            }}>
                                            {translate('btn_cancel')}
                                        </Button>

                                        <Button
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return handleSubmit(e);
                                            }}
                                            className="rounded height-40 w-100px triper-button bg-green-300"
                                            disabled={!canEdit}
                                            type="submit">
                                            {translate('btn_submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />

                    {priceGroupTabId !== 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <Form
                                    onSubmit={onSubmitCols}
                                    initialValues={{ colsValue: 1 }}
                                    render={({ handleSubmit, form }) => (
                                        <form id="form" onSubmit={handleSubmit}>
                                            <Grid
                                                container
                                                direction={'row'}
                                                justifyContent={'flex-start'}
                                                alignItems={'center'}
                                                spacing={1}>
                                                <Grid item xs={1} sm={2}>
                                                    <InputLabel>
                                                        {translate(
                                                            'price.column_label'
                                                        )}
                                                    </InputLabel>
                                                    <Field name="colsValue">
                                                        {({ input }) => (
                                                            <TextField
                                                                {...input}
                                                                variant="outlined"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value <
                                                                        0
                                                                            ? 0
                                                                            : e
                                                                                  .target
                                                                                  .value >
                                                                              20
                                                                            ? 20
                                                                            : e
                                                                                  .target
                                                                                  .value;
                                                                    input.onChange(
                                                                        value
                                                                    );
                                                                }}
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        type="submit"
                                                        onClick={(e) => {
                                                            return handleSubmit(
                                                                e
                                                            );
                                                        }}>
                                                        {translate(
                                                            'price.add_column_btn'
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                />
                            </div>
                            <ReactGrid
                                rows={rows}
                                columns={columns}
                                onCellsChanged={handleChanges}
                            />
                        </div>
                    )}
                </>
            )}
        </Layout>
    );
};

PricePage.displayName = 'PricePage';

export default PricePage;
