import { PriceService } from 'core/api/services/price';
import React, { useContext, useState } from 'react';

const PriceContext = React.createContext(null);

export const PriceProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState([]);
    const [expPg, setExpPg] = useState([]);
    const [experiencerId, setExperiencerId] = useState(null)
    const [expPgTab, setExpPgTab] = useState([]);
    const [total, setTotal] = useState([]);

    const getPriceGroupTabs = async (options) => {
        try {
            setLoading(true);
            const result = await PriceService.getList(options);

            if (!result) {
                throw new Error();
            }
            setPrices(result.prices);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getPriceGroupTab = async (id) => {
      try {
        setLoading(true);
        const result = await PriceService.getById(id);
        if (!result) {
          throw new Error();
        }
        setExpPgTab(result[0])
        return true;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const getExpPg = async (id) => {
      try {
          setLoading(true);
          const result = await PriceService.getListExpPg(id);

          if (!result) {
              throw new Error();
          }
          setExpPg(result[0]);
          setExperiencerId(result[1].experiencerId)
          return result[0];
      } catch (err) {
          console.error(err);
          return false;
      } finally {
          setLoading(false);
      }
  };

  const addExpPg = async (values) => {
    try {
        setLoading(true);
        const result = await PriceService.addExpPg(values);

        if (!result) {
            throw new Error();
        }

        return result;
    } catch (err) {
        console.error(err);
        return false;
    } finally {
        setLoading(false);
    }
};

    const addPriceGroupTab = async (values) => {
      try {
        setLoading(true);
        const price = await PriceService.add(values);
        if (!price) {
          throw new Error();
        }
        return price;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const updatePriceGroupTab = async (pgtId, values) => {
      try {
        setLoading(true);
        const result = await PriceService.update(pgtId, values);
        if (!result) {
          throw new Error();
        }
        return result;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const deletePriceGroupTab = async (id, pgtId) => {
      try {
        setLoading(true);
        const result = await PriceService.delete(id, pgtId);
        if (!result) {
          throw new Error();
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const addPrice = async (id, values) => {
      try {
        setLoading(true);
        const price = await PriceService.addPrice(id, values);
        if (!price) {
          throw new Error();
        }
        return price;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const updatePrice = async (id, priceId, values) => {
      try {
        setLoading(true);
        const result = await PriceService.updatePrice(id, priceId, values);
        if (!result) {
          throw new Error();
        }
        return result;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const updatePriceCurrency = async (id, values) => {
      try {
        setLoading(true);
        const result = await PriceService.updatePriceCurrency(id, values);
        if (!result) {
          throw new Error();
        }
        return result;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        setLoading(false);
      }
    };

    return (
        <PriceContext.Provider
            value={{
                loading,
                total,
                prices,
                expPg,
                experiencerId,
                expPgTab,
                getExpPg,
                getPriceGroupTabs,
                getPriceGroupTab,
                addPriceGroupTab,
                updatePriceGroupTab,
                deletePriceGroupTab,
                addPrice,
                updatePrice,
                updatePriceCurrency,
                addExpPg,
                setExpPgTab
            }}>
            {children}
        </PriceContext.Provider>
    );
};

PriceProvider.displayName = 'PriceProvider';

export const usePrice = () => useContext(PriceContext);
